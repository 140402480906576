/* .cart_wrap, .profile_edit_wrap {
    width: 100%;
    max-width: 1276px;
    margin: 0 auto;
}
.cart {
    background-color: white;
    border-radius: 10px;
}
.items_incart {
    padding: 30px 0;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--pe_blue);
}

.items_incart span {
    border-bottom: 3px solid var(--pe_blue);
}

.checkout-wrap .cl_items {
    padding: 25px 0;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
}
.cl_head .cart_wrap, .cl_items {
    display: flex;
    flex-wrap: wrap;
    font-weight: 700;
    text-transform: uppercase;
} */


/* checkout-css */
.checkout_wrap .checkout_cart_wrap {
    /* width: 70%; */
    padding: 25px 25px;
    margin: 0 auto;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
}

.checkout_wrap .checkout_cart_wrap .input-group {
    display: flex;
}

.checkout_wrap .input-group .form-control:last-child {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.checkout_wrap .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 5px !important;
}

.checkout_wrap .checkout_cart_wrap .input-group .form_field input {
    margin-right: 15px;
    border-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.checkout_wrap .checkout_cart_wrap .input-group .form_field textarea,
button {
    margin-right: 15px;
    border-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.checkout_wrap .checkout-form .checkout_form_wrapper {
    display: flex;
    padding: 15px 0;
}

.checkout_wrap .checkout-form .checkout_form_wrapper .input-group {
    width: 100%;
    padding-right: 10px;
}

.checkout_wrap .checkout_form_wrapper .form_field .dropdown .button {
    width: 100%;
}

.checkout_wrap .checkout_form_wrapper .form_field .dropdown .dropdown-menu {
    margin-top: 30px !important;
}

.payment_method_section {
    background: #c0b8c8;
    border: 1px solid #E3E3E3;
    opacity: 1;
    padding: 25px 10px;
    margin: 25px auto;
    border-radius: 15px;
    max-width: 600px;
}

.payment_method_section ul {
    padding-left: 0;
}

.payment_method_section ul li {
    list-style: none;
    font-size: 15px;
    line-height: 36px;
    letter-spacing: 0.05rem;
}

.ElementsApp input {
    border: 1px solid #000 !important;
    height: 36px !important;
    line-height: 36px !important;
    padding: 0px 5px !important;
    width: 100% !important;
}

.payment_method_section .payment_method_wrapper .payment_list {
    display: flex;
}

/* .StripeElement iframe {
    height: 36px !important;
} */

.CardField-input-wrapper {
    margin: 15px 0px;
}

.CardField-input-wrapper .CardField-number input,
.CardField-input-wrapper .CardField-restWrapper input,
.customCardElement input {
    padding: 5px !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.payment_method_section .subtotal_wrapper {
    margin-bottom: 15px;
}


/* product cart */

.checkout_wrap .checkout_cart_element {
    width: 90%;
    padding: 35px 35px;
    margin: 0 auto;
    /* border: 1px solid #E3E3E3; */
    border-radius: 10px;
}