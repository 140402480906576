body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ********************************************* */

/*New CSS*/
/* html,
body {
  min-width: 0 !important;
}
#zmmtg-root {
  display: none;
  min-width: 0 !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
} */

/* ********************************************* */
/* sir added css */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


html,
body {
  height: auto !important;
  width: 100%;
  overflow: visible !important;

  font-family: "Droid Sans", sans-serif !important;
}
#root .btn-group,
#root .btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
#root {
  position: relative !important;
} */
